
/*!
* KNACSS Reborn: Just keep it simple!
* @author: Alsacreations
* v8.0.4 2021/04
* Licence WTFPL http://www.wtfpl.net/
*/

// IMPORTANT : Seuls les fichiers "Noyau" 
// et "Utilitaires" sont importés par défaut. 
// Ajoutez les fichiers de composants si nécessaires dans votre projet.

// NOYAU
@import "abstracts/variables-sass";
@import "abstracts/mixins-sass";

@import "base/reset-base";
@import "base/reset-accessibility";
@import "base/reset-forms";
@import "base/reset-print";
//@import "base/layout";
@import "base/layout-magic";

// UTILITAIRES
@import "utils/utils-global";
@import "utils/utils-font-sizes";
@import "utils/utils-spacers";
@import "utils/grillade";

// COMPOSANTS (à ajouter au besoin)
@import "components/button";
// @import "components/burger";
// @import "components/checkbox";
// @import "components/radio";
// @import "components/select";
@import "components/quote";
