/*
 * basic button component
 */
/*
 * HTML template example:
 * preferably use <button> for buttons !
 * use .button or .btn (for structure) and .btn-- (for variants) (see appearance mixin)
 */

/*
 * button reset
 */

%btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: $spacer-small $spacer-small-plus;
  cursor: pointer;
  user-select: none;
  transition: 0.25s;
  transition-property: box-shadow, background-color, color, border;
  text-decoration: none;
  border: none;
  box-shadow: none;
}

.btn,
.button {
  @extend %btn;

  &:focus {
    -webkit-tap-highlight-color: transparent;
  }
}

// button state variants
.btn,
.button {
  &--small {
    padding: $spacer-tiny-plus $spacer-small;
    font-size: .8em;
  }

  &--big {
    padding: $spacer-small-plus $spacer-medium;
    font-size: 1.4em;
  }

  &--block {
    width: 100% !important;
    display: block;
  }

  &--unstyled {
    padding: 0;
    border: none;
    text-align: left;
    background: none;
    border-radius: 0;
    box-shadow: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    &:focus {
      box-shadow: none;
      outline: none;
    }
  }
}