@charset "utf-8";

@import "knacss";
@import "fontawesome/fontawesome.scss";

$background-color: #fdfdfd !default;
$color: $color-gray-4;
$background-color-alternate: #23909b; // #3c8289;
$color-alternate:#4dc4d0; // #2cb5ae;
$background-color-myblack: #292929;
$logo-color: #f4a261 !default; // #ff9e5e !default;
$background-logo-color: #b65b2e !default;
$inverted-color: #fdfdfd;
$blockquote-color: #bdc3c7;

@font-face {
  font-family: "LearningCurve";
  src: url("../assets/fonts/LearningCurve.ttf");
}

@font-face {
    font-family: "Manrope";
    src: url("../assets/fonts/Manrope-VariableFont_wght.ttf");
}

body {
    font-family: "Manrope";
    font-size: 1.2rem;
    line-height: 1.4;
    color: #003049;
}

.citation {
    font-family: "LearningCurve";
    font-size: 2.1rem;
}


/*
p {
    color: blue;
    @include respond-to("small-up") {
        color: hotpink;
    }
}
*/

a {
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}

h2 {
    margin-top: 2rem;
    font-size: 3rem;
    @include respond-to("small") {
        font-size: 2rem;
        text-align: center;
    }
}



.site-footer-wrapper {
    background-color: $background-color-alternate;
}

.site-footer-wrapper a {
    color: $color;
}

.site-footer-wrapper__footfoot {
    background-color: $background-color-myblack;
    color: #707070;
    font-size: small;
    padding: 3rem;
}

.site-footer-wrapper__footfoot__container {
    @include respond-to("small-up") {
        width: 1024px;
        margin: auto;
    }
}

.blog-background {
    background-image: url(../assets/img/blog-bg-black.jpg) !important;
    min-height: 390px;
}

.link-blog {
    font-family: Montserrat, sans-serif;
    font-size: 5rem;
    color: $inverted-color;
    text-decoration: none;
    @include respond-to("small") {
        font-size: 3rem;
    }
}

.link-blog:hover {
    text-decoration: underline;
    cursor: pointer;
    color: $inverted-color;
}

.link-blog:visited {
    color: $inverted-color;
}

.card {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    border-radius: $radius-small;
    background-color: white;
}

.card a {
    text-decoration: none;
}

.card img {
    border-radius: $radius-small $radius-small 0 0;
}

.card:hover {
    box-shadow: 0 16px 16px 0 rgba(0, 0, 0, 0.2);
}

.card_hero {
    background-color: antiquewhite;
}

.antiquewhite a {
    color: antiquewhite;
}

.card_container {
    padding: 1rem;
}


.link-social {
    display: inline-block;
    text-decoration: none;
    width: 35px;
    height: 35px;
    color: $color-alternate !important;
}

.foot-icon {
    mask: url(icons/icon-foot.svg);
    mask-size: cover;
    background: $color-alternate;

}

.avatar {
    border-radius: 100%;
    padding: 5px;
    width: 60px;;
}

.color-white {
    color: white !important;
}

.contact_form input[type=text], input[type=email], select, textarea {
  width: 100%;
  padding: .5rem;
  background-color:whitesmoke;
  color: $color;
  border-radius: .4rem;
  margin-bottom: 1rem;
  resize: vertical;
  @include respond-to("small-up") {
    width: 75%;
  }
}

.contact_form input[type=submit] {
  background-color: $color-alternate;
  color: black;
  padding: 5px 20px;
  border: none;
  border-radius: 2rem;
  cursor: pointer;
}

.contact_form input[type=submit]:hover {
  background-color: whitesmoke;
}

/* override knacss */
.layout-hero {
    background: $background-logo-color;
    color: #fff;
}

.btn,
.button {
    background-color: $logo-color;
    padding: 0.4rem 1.6rem;
    margin: 0.4rem 0;
    font-size: 1.4rem;
    font-weight: 700;
    box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.2509803922);
}

blockquote {
   color: $blockquote-color;
}

blockquote:before {
   color: $blockquote-color;
}

/* nav responsive from minima theme*/
.site-nav {
    float: right;
    /*line-height: $base-line-height * $base-font-size * 2.25;*/

    .nav-trigger {
        display: none;
    }

    .menu-icon {
        display: none;
    }

    .page-link {
        text-decoration: none;
        // Gaps between nav items, but not on the last one
        &:not(:last-child) {
            margin-right: 20px;
        }
    }

    .page-link:hover {
        text-decoration: underline;
    }

    @include respond-to("small") {
        position: absolute;
        top: 9px;
        right: 9px;
        /*right: $spacing-unit / 2;*/
        background-color: white;
        //border: 1px solid gray;
        border-radius: 5px;
        text-align: right;

        label[for="nav-trigger"] {
            display: block;
            float: right;
            width: 36px;
            height: 36px;
            z-index: 2;
            cursor: pointer;
        }

        .menu-icon {
            display: block;
            float: right;
            width: 36px;
            height: 26px;
            line-height: 0;
            padding-top: 10px;
            text-align: center;

            > svg {
                fill: darkgrey;
            }
        }

        input ~ .trigger {
            clear: both;
            display: none;
        }

        input:checked ~ .trigger {
            display: block;
            padding-bottom: 5px;
        }

        .page-link {
            display: block;
            padding: 5px 10px;

            &:not(:last-child) {
                margin-right: 0;
            }
            margin-left: 20px;
        }
    }
}

/* css tooltip from w3schools */

/* Tooltip container */
.tooltip {
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted black;
    /* If you want dots under the hoverable text */
}

/* Tooltip text 
   usage 
   <div class="tooltip">Hover over me 
       <span class="tooltiptext">Tooltip text</span>
   </div>
*/
.tooltip .tooltiptext {
    visibility: hidden;
    min-width: 120px;
    width: max-content;
    max-width: 300px;
    background-color: black;
    color: #fff;
    padding: 7px;
    border-radius: 6px;

    /* Position the tooltip text - see examples below! */
    position: absolute;
    z-index: 1;
    top: 100%;
    left: 50%;
    margin-left: -60px;

    font-size: small;
    line-height: normal;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
    visibility: visible;
}

/* add arrow */
.tooltip .tooltiptext::after {
    content: " ";
    position: absolute;
    bottom: 100%;
    /* At the top of the tooltip */
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent black transparent;
}

.arrow {
    margin: 3rem auto;
    width: 1rem;
}

.icon-container {
      position: relative;
      display: inline-block;
}

.icon-container:before {
      content: "";
      position: absolute;
      width: 100%;
      height: 2px;
      background-color: black;
      top: 50%;
      left: 0;
    transform: rotate(-45deg);
  }