:root {
    --spacer-small: 2rem;
    --bgc: chocolate;
}

/* This layout is pure magic!! */

.layout-magic {
  --layout-size: 1024px;
  display: grid;
  grid-template-columns:
   [layout-start] minmax(var(--spacer-small), 1fr)
   [content-start] minmax(auto, var(--layout-size)) [content-end]
   minmax(var(--spacer-small), 1fr) [layout-end];
}

/* Center all children */
.layout-magic > * {
  grid-column: content-start / content-end;
}

/* Hero box with background */
.layout-hero {
  display: grid;
  grid-template-columns: inherit;
  grid-column: layout-start / layout-end;
  background: var(--bgc, hotpink);
  color: #fff;
}

.layout-hero > * {
  grid-column: content-start / content-end;
}

/* Hero image */
.hero-img {
  grid-column: layout-start / layout-end;
  justify-self: center;
  max-width: 100%;
}

